import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

//Old Account
// const firebaseConfig = {
//   apiKey: "AIzaSyALBlJIjv-7j9S9skD92BEnnmv3ThmPlz0",
//   authDomain: "javatimescaffe-46f80.firebaseapp.com",
//   projectId: "javatimescaffe-46f80",
//   storageBucket: "javatimescaffe-46f80.appspot.com",
//   messagingSenderId: "720474652082",
//   appId: "1:720474652082:web:3fe734336c7303b70478ab",
//   measurementId: "G-C8B7XG14KM"
// };

//New Account
const firebaseConfig = {
  apiKey: "AIzaSyCPGrBB7zXW14ompkFhDs0zyN8u3RN53vE",
  authDomain: "jtc-pos.firebaseapp.com",
  projectId: "jtc-pos",
  storageBucket: "jtc-pos.appspot.com",
  messagingSenderId: "837126375756",
  appId: "1:837126375756:web:a7a463b6c26567aa8327e9",
  measurementId: "G-SF38CQJ8ZG"
};


const isChrome = navigator.userAgent.indexOf("Chrome") != -1;

// Initialize Firebase and messaging only if browser is Chrome
  export const firebaseApp = isChrome ? initializeApp(firebaseConfig) : null;
  export const messaging = isChrome ? getMessaging(firebaseApp): null;
