import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import useNavigate from "hooks/Navigate";
import {
  About,
  AdminLogin,
  BlogPage,
  Contact,
  ContactUs,
  FranchiseDetail,
  Home,
  Investment,
  InvestNow,
  InvestorForm,
  LegalEntityInvestorForm,
  Login,
  Menu,
  NotFound,
  NotificationDetails,
  Notifications,
  Offices,
  OtpVerify,
  PrivacyPolicy,
  Race,
  Registration,
  Shop,
  TeamMember,
  TermOfUse,
  WeAreJava,

} from "./screens";
import {
  CheckoutForm,
  Footer,
  Header,
  PageLoader,
  ProgressBar,
  ProtectedRoute,
  ScrollToTop,
} from "components";
import DashboardContent from "screens/UserDashboard/Dashboard";
import { UserInvest,Franchise } from "./screens";
import React, { useEffect, useState } from "react";
import UserDetails from "./components/admin/userDetails";
import UpdateStore from "./components/admin/UpdateStore";
import Wallet from "./screens/UserDashboard/Wallet";
import CreateAdmin from "./components/admin/CreateAdmin";

import {
  AddUpdateBlog,
  AddUpdateStore,
  AddUser,
  AdminDashboard,
  Dividend,
  Stores,
  DividendHistory,
  Transactions,
  Users,
  AdminTransactions,
  DonationShareTransfer,
  DonationShareHistory,
  Blogs,
  WithDrawl,
  AdminWithDrawl,
  WithDrawlRequest,
  InvestorsPitch,
  AdminInvest,
  AddUpdateUser,
  AdminUsers,
  SubAdminWithDrawl,
  UserDocuments,
  AdminDocuments,
  AdminSendNotification,
  NotificationsHistory,
  AnnualProgress,
  SharePriceHistory,
  DonationTokenHistory,
  DonationTokenTransfer
} from "screens/Admin";

import { LandingPage } from "screens";
import { useDispatch, useSelector } from "react-redux";
import { setTotalUnseenNotifications, setUser, setUserNotifications } from "redux/slices/userSlice";
import Language from "./components/Language";
import { teamMembers } from "dummyData";
import { adminLogout, setAdminNotifications, setTotalUnseenAdminNotifications } from "redux/slices/adminSlice";
import { walletNull } from "redux/slices/walletSlicer";
import { logoutUser } from "redux/slices/userSlice";
import KYCRegistration from "screens/KYCRegistration";
import { toast } from "react-toastify";
import ForgetPassword from "screens/ForgetPassword";
import UpdatePassword from "screens/UpdatePassword";
import { Suspense } from "react";
import NewRegistrationForm from "components/forms/NewRegistrationForm";
import { Live_URL, Local_URL } from "assets/constants";
import Document from "./screens/UserDashboard/Document";

import { io } from "socket.io-client";
import PromptModal from "components/modals/PromptModal";
import KYCModal from "components/modals/KYCModal";
import SignatureModal from "components/modals/SignatureModal";
import SignatureSuccessModal from "components/modals/SignatureSuccessModal";
import Logout from "screens/Logout";
// import { onMessage } from "firebase/messaging";
// import { messaging } from "./firebase";
import apis from "services";
import Referral from "screens/Admin/Referral";
import UserReferral  from "screens/UserDashboard/UserReferral";
import UserReferralPolicy from "screens/UserDashboard/UserReferralPolicy";
import AddAnnualProgress from "screens/Admin/AddAnnualProgress";
import WhatsappConnect from "components/common/WhatsappConnect";

const socket = io(Live_URL);

const App = () => {
  const dispatch = useDispatch();
  const { user, user_analytics } = useSelector((store) => store.user);

  const { adminUser } = useSelector((store) => store.admin);
  const { pathname } = useLocation();
  socket.on("sessionExpired", (token) => {
    console.log(token, "token");
    // if (token) {
    //   dispatch(adminLogout());
    //   dispatch(logout());
    //   dispatch(walletNull());
    //   toast.error("SESSION EXPIRED", { id: 1 });
    // }
  });

  socket.on("verificationSuccess", (userData) => {
    console.log(userData, "userDatauserDatauserData")
    if (userData?.email === user?.email) {
      dispatch(setUser({ id: userData?._id, ...userData }));
    }
  });


  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  console.log(pathname, "pathnam123123e");


  const [investmentModal, setInvestmentModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const toggleSuccessModal = () => {
    setSuccessModal((prevState) => !prevState);
  };

  const toggleInvestmentModal = (type = "close") => {
    if (type === "open") {
      setSignatureModal(true);
      setInvestmentModal(false)
    }
    else if (type === "success") {
      setSuccessModal(true)
      setSignatureModal(false);
      setInvestmentModal(false)
    }
    else if (user?.isKYCCompleted === 'pending') {
      setInvestmentModal((prevState) => !prevState);
      setSignatureModal(false);
    }
    else if (user?.isKYCCompleted === "progress") {
      setSignatureModal((prevState) => !prevState);
      setInvestmentModal(false)
    }
  }


  useEffect(() => {
   
   
   
    if (!user?.isAdminApproved && user && user_analytics?.approved + user_analytics?.pending !== 8 && user?.isInvestor && (user?.isKYCCompleted === "pending" || user?.isKYCCompleted === "progress")) {

      const intervalId = setInterval(() => {
        if (!showModal && (pathname !== "/en/user/document" && pathname !== "/sp/user/document")) {
          setShowModal(true);
        }
      }, 10000);

      return () => {
        clearInterval(intervalId); // Clean up the interval on component unmount
      };

    }


     else if (user && (user?.isAdminApproved  || user_analytics?.approved === 8) && user?.isInvestor && user?.isKYCCompleted === "pending") {
      const intervalId = setInterval(() => {
        if (!investmentModal) {
          setInvestmentModal(true);
        }
      }, 10000);

      return () => {
        clearInterval(intervalId); // Clean up the interval on component unmount
      };
    }
    else if (user &&  (user?.isAdminApproved  || user_analytics?.approved === 8) && user?.isInvestor && user?.isKYCCompleted === "progress") {
      const intervalId = setInterval(() => {
        if (!signatureModal) {
          setSignatureModal(true);
        }
      }, 10000);

      return () => {
        clearInterval(intervalId); // Clean up the interval on component unmount
      };
    }


  }, [pathname, user, showModal]);


  // onMessage(messaging, async (payload) => {
  //   console.log('Message received. ', payload);
    
  // });


  socket.on("notification", (data) => {
    if (user) {
      if (user?.id === data?.userId) {
        dispatch(setUserNotifications(data?.notifications))
        dispatch(setTotalUnseenNotifications(data?.unSeenNotifications));
      }
    }
  });


  socket.on("notification-admin", (data) => {
    if (adminUser) {
      if (adminUser?.id === data?.adminId) {
        dispatch(setAdminNotifications(data?.notifications))
        dispatch(setTotalUnseenAdminNotifications(data?.unSeenNotifications));
      }
    }
  });


  return (
    <>
      {user?.isVerified && user?.profileCompleted > 0 &&
        <ProgressBar bgcolor="#e11f1c" completed={user?.profileCompleted} />
      }
      <ScrollToTop />
      <WhatsappConnect/>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {/* <Route path="/"> */}

          <Route path="/" element={<Language />}>
            <Route path="/:lang">
              <Route index element={<LandingPage />} />
              <Route path="login" element={<Login />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              
              <Route path="term-of-use" element={<TermOfUse />} />
             
              
              <Route path="registration" element={<NewRegistrationForm />} />
              <Route path="forget" element={<ForgetPassword />} />
              <Route path="admin-login" element={<AdminLogin />} />
              <Route path="verify" element={<OtpVerify />} />
              <Route path="contact-us" element={<ContactUs />} />
              {/* <Route path="investors-pitch" element={<InvestorsPitch />} /> */}
              <Route path="about-us" element={<About />} />

              <Route path="referral-policy" element={<UserReferralPolicy />} />

              
              {/* <Route
                path="investors-pitch/:id"
                element={<InvestorsPitch />}
              /> */}
              <Route path="blog/:title" element={<BlogPage />} />
              <Route path="logout" element={<Logout />} />

              <Route
                path="update-password/:email"
                element={<UpdatePassword />}
              />

              {/* <Route path="/update-password" element={<UpdatePassword/>} /> */}

              <Route
                path="team/:id"
                element={<TeamMember teamMembers={teamMembers} />}
              />
              {/* User Route */}
              <Route element={<ProtectedRoute auth />}>


                <Route
                  path="notification/:id"
                  element={<NotificationDetails />}
                />

                {/* <Route path="invest" element={<UserInvest />} /> */}
                <Route path="franchise" element={<Franchise />} />
                    ``<Route
                    path="franchise/:id"
                    element={<FranchiseDetail />}
                  />``
                <Route path="we-are-java" element={<WeAreJava />} />
                <Route path="offices" element={<Offices />} />
                {/* <Route path="contact" element={<Contact />} /> */}

                <Route path="invest-now" element={<InvestNow />} />

                <Route
                  path="legal-entity-investor"
                  element={<LegalEntityInvestorForm />}
                />
                <Route path="kyc-registration" element={<KYCRegistration />} />

                {/* <Route path="new-registration" element={<NewRegistrationForm />} /> */}
                <Route path="notifications" element={<Notifications />} />


                <Route
                  path="investment-registration"
                  element={<Registration />}
                />
                {/* <Route path="admin/master/YWRtaW4=" element={<CreateAdmin />} /> */}
              </Route>
              {/*NOTE:: Admin Private Routes here*/}
              <Route path="admin/" element={<ProtectedRoute isAdmin />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="users" element={<Users />} />
                <Route path="users-document" element={<UserDocuments />} />

                <Route path="users-document/:id" element={<AdminDocuments />} />
                
                <Route path="annual-progress" element={<AnnualProgress />} />
                <Route path="annual-progress/add" element={<AddAnnualProgress />} />
               
                

                <Route path="user-details/:id" element={<UserDetails />} />
                <Route path="stores" element={<Stores />} />
                <Route path="dividend" element={<Dividend />} />
                <Route path="stores/add/" element={<AddUpdateStore />} />
                <Route path="stores/add/:id" element={<AddUpdateStore />} />
                <Route path="store/update/:id" element={<UpdateStore />} />
                <Route path="users/add" element={<AddUser />} />
                <Route path="users/add/:id" element={<AddUpdateUser />} />

                <Route path="transactions" element={<Transactions />} />
                <Route path="admin-transactions" element={<AdminTransactions />} />

                <Route path="dividend-history" element={<DividendHistory />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="investor" element={<InvestorForm />} />
                <Route path="withdrawl" element={<AdminWithDrawl />} />
                <Route path="admin-invest/:id" element={<AdminInvest />} />
                <Route path="send-notification" element={<AdminSendNotification />} />

                <Route path="notification-history" element={<NotificationsHistory />} />
                <Route path="share-price-history" element={<SharePriceHistory />} />

                
                <Route path="referral-history" element={<Referral />} />


                
                <Route
                  path="withdrawl-request"
                  element={<WithDrawlRequest />}
                />
                <Route
                  path="share-transfer"
                  element={<DonationShareTransfer />}
                />
                  <Route
                  path="token-transfer"
                  element={<DonationTokenTransfer />}
                />
                <Route
                  path="share-transfer-history"
                  element={<DonationShareHistory />}
                />
                <Route
                  path="token-transfer-history"
                  element={<DonationTokenHistory />}
                />


                <Route path="blog" element={<Blogs />} />
                <Route path="blog/add/:id" element={<AddUpdateBlog />} />
                <Route path="blog/add/" element={<AddUpdateBlog />} />
              </Route>

              {/*NOTE:: Sub Admin Private Routes here*/}
              <Route path="sub-admin/" element={<ProtectedRoute isSubAdmin />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="blog" element={<Blogs />} />
                <Route path="blog/add/:id" element={<AddUpdateBlog />} />
                <Route path="blog/add/" element={<AddUpdateBlog />} />
                <Route path="stores" element={<Stores />} />
                <Route path="stores/add/" element={<AddUpdateStore />} />
                <Route path="stores/add/:id" element={<AddUpdateStore />} />
                <Route path="store/update/:id" element={<UpdateStore />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="users" element={<AdminUsers />} />

                <Route path="withdrawl" element={<SubAdminWithDrawl />} />
              </Route>

              {/*NOTE:: User Private Routes here*/}
              <Route exact path="user/" element={<ProtectedRoute />}>
                <Route path="dashboard" element={<DashboardContent />} />
                <Route path="withdrawl" element={<WithDrawl />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="document" element={<Document />} />
                <Route path="referral" element={<UserReferral />} />

                
                <Route path="checkout" element={<CheckoutForm />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>

      <PromptModal toggleModal={toggleModal}
        status={showModal}
      />


      {user?.isKYCCompleted === "pending" && (
        <KYCModal
          toggleModal={toggleInvestmentModal}
          status={investmentModal}
          userId={user?.id}
          signature={true}
        />
      )}


      {user?.isKYCCompleted === "progress" && (
        <SignatureModal
          toggleModalSignature={toggleInvestmentModal}
          status={signatureModal}
          userId={user?.id}
        />
      )}

      <SignatureSuccessModal
        toggleModalSuccess={toggleSuccessModal}
        status={successModal}
        userId={user?.id}
      />


    </>
  );
};

export default App;
