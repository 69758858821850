import {Box, TextField} from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Button, Container, Modal} from "react-bootstrap";
import {memo, useEffect, useRef, useState} from "react";
import apis from "../services";

import useNavigate from "hooks/Navigate";
import {useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
// import { getWallet } from "../redux/slices/walletSlicer";


const EntityModal = memo((props) => {
    const {status, toggleModal, _id: id, sharePerPrice, totalShares} = props;

    //const {sharePerPrice, totalShares} = STATIC_STORE_DATA;



    const [data,setData]=useState({
        "totalInvestor": 0,
        "totalStoreFundedAmount": 0,
        "TOTAL_SHARES": 120000000,
        "totalAvailableShares": 0,
        "TOTAL_PRICE_PER_SHARE": 20
      });
        const fetchData = async () => {
          const res = await apis.getTotalRecord();
          setData(res?.data);
        };
      
        useEffect(()=>{
          fetchData();
        },[])
      

    const navigate = useNavigate();
    const shareAmountRef = useRef(null);
    const [loading, setLoading] = useState(false);
    
    const [walletLoading, setWalletLoading] = useState(false);
    const {user} = useSelector((store) => store.user);
    const [shareAmount, setShareAmount] = useState(0);
    const {t} = useTranslation();

    const onSubmitHandler = async(e) => {
     
        if (shareAmount <= 0) {
            if(e =="card")
                {
                    setLoading(false)
         
                }else{
                 setWalletLoading(false)
                }
            return toast.error(`Purchase share number can't be ${shareAmount}`);
        }
        if ((shareAmount * data?.TOTAL_PRICE_PER_SHARE) < 500 && e =="stp") {
            if(e =="card")
                {
                    setLoading(false)
         
                }else{
                 setWalletLoading(false)
                }
                
            return toast.error(`The minimum purchase requirement is 500 mexican pesos.`);
        }
        if (shareAmount < 5 && e =="wallet") {
            if(e =="wallet")
                {
                    setLoading(false)
         
                }else{
                 setWalletLoading(false)
                }
                
            return toast.error(`The minimum purchase requirement is 5 shares.`);
        }
        // if (shareAmount > totalShares) {
        //     if(e =="card")
        //         {
        //             setLoading(false)
         
        //         }else{
        //          setWalletLoading(false)
        //         }
        //     return toast.error(`You cannot purchase ${shareAmount}, total available shares are: ${totalShares}`)
        // }

        const {id: _id, title_en,title_sp, description_en,description_sp, price = shareAmount} = props;

        if(e =="card")
            {
                setLoading(true)
     
            }else{
             setWalletLoading(true)
            }
         const res =  await apis.createOrder({userId:user?.id,storeId:id,purchasedShare:shareAmount,amountInvested:shareAmount * data?.TOTAL_PRICE_PER_SHARE,clabe : user?.clabe});
         if(res?.data?.status){
            if(e =="card")
                {
                    setLoading(false)
         
                }else{
                 setWalletLoading(false)
                }
            navigate('/user/checkout', {state: { title_en,title_sp, description_en,description_sp, price, id, sharePerPrice:data?.TOTAL_PRICE_PER_SHARE, totalShares,type:e}})
         }
         if(e =="card")
            {
                setLoading(false)
     
            }else{
             setWalletLoading(false)
            }
    }

    return (
        <Container>
            <Box component="form">
                <Modal show={status} onHide={toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("titles.investment_details")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {t("labels.total_shares_in_pool")} : {totalShares}{" "}
                                    </TableCell>
                                    <TableCell>
                                        {t("labels.per_price_share")} : {data?.TOTAL_PRICE_PER_SHARE}{" "}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <TableCell aria-label="a dense table">
                            <div
                        dangerouslySetInnerHTML={{
                          __html: 
                          t("titles.descriptionInvest")
                        }}
                      ></div>
                      <div className="mt-2">
                      <strong>{t("labels.how_many_shares")}</strong> 
                      </div>
                        </TableCell>

                        <TextField
                            inputRef={shareAmountRef}
                            id="shareAmount"
                            amount="shareAmount"
                            type='number'
                            fullWidth
                            margin="normal"
                            label={t("placeholders.share")}
                            required
                            InputProps={{inputProps: {min: 1}}}
                            onChange={(e) => setShareAmount(e.target.value)}
                        />
                        <h3>
                            {t("labels.total_investment")}: ${data?.TOTAL_PRICE_PER_SHARE * shareAmount}
                        </h3>
                    </Modal.Body>
                    <Modal.Footer>
                    {/* <Button onClick={toggleModal} className="lp-btn-invest inverted-btn">
                            {t("buttons.cancel")}
                        </Button> */}
                         <Button  onClick={()=>onSubmitHandler("wallet")}  className="lp-btn-invest inverted-btn">
                           {walletLoading ? t("buttons.in_process") : t("buttons.proceed_wallet")}
                        </Button>
                        <Button onClick={()=>onSubmitHandler("card")} className="lp-btn-invest" disabled={loading}>
                            {loading ? t("buttons.in_process") : t("buttons.proceed_stp")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Box>
        </Container>
    );
});

export default EntityModal;
