import React from "react";
import { FaWhatsappSquare } from "react-icons/fa";

const WhatsappConnect = () => {
  return (
    <>
      <div className="fixed-arr" style={{
    position: 'fixed',
    bottom: '30px', // Adjust this value to your preference
    right: '35px', // Adjust this value to your preference
    zIndex: 1000, // Ensure it appears above other content
  }}>
          <a href="https://wa.me/528711236511?text=How can i help you?" target="_blank">

      
      <FaWhatsappSquare color="#25D366" bgcolor="#fff" size={"60px"}/>
          </a>
        </div>
    </>
  );
};

export default WhatsappConnect;
